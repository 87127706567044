
import BotFeaturesCheckboxGroup from '@/components/bot/BotFeaturesCheckboxGroup.vue';
import BotForm from '@/components/bot/BotForm.vue';
import { BotMixin } from '@/mixins/BotMixin';
import { CreateCollaborationInvitation, UserRole, UserStatus, UserToBot } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import InviteCollaboratorModal from '@/components/bot/InviteCollaboratorModal.vue';
import { BotApi } from '@/api';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { AuthMixin } from '@/mixins/AuthMixin';

@Component({
  name: 'bot-collaboration-settings',
  components: { InviteCollaboratorModal, BotForm, BotFeaturesCheckboxGroup },
})
export default class BotCollaborationSettings extends mixins(BotMixin, AuthMixin) {
  showModal = false;

  get collaborators(): UserToBot[] {
    return this.bot.userToBot || [];
  }

  isPendingStr(userToBot: UserToBot): string {
    return userToBot.status === UserStatus.Pending ? '(Pending invitation)' : '';
  }
  userRoleStr(userToBot: UserToBot): string {
    switch (userToBot.role) {
      case UserRole.Admin:
        return '- Admin';
      case UserRole.Editor:
        return '- Editor';
      case UserRole.AnalyticsViewer:
        return '- Analytics Viewer';
      default:
        return '';
    }
  }

  async handleDeleteCollaboration(userToBot: UserToBot) {
    try {
      if (confirm('Do you really want to remove this user?')) {
        await BotApi.deleteCollaboration(this.bot.id, userToBot.user.id);

        const collaborators = this.collaborators.filter((utb) => utb.user.id !== userToBot.user.id);

        this.$store.commit('bots/updateBot', {
          ...this.bot,
          userToBot: collaborators,
        });

        this.$notify.success('Successfully deleted');
      }

      // this.$emit('update:show', false);
      // this.reset();
    } catch (e) {
      this.$notify.error({
        title: 'Sending invites failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    }
  }
}
