
import BotSettingsCard from '@/components/bot/BotSettingsCard.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { ProjectViewMixin } from '@/mixins/ProjectViewMixin';
import { AppRoute } from '@/router/routes';
import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import { AuthMixin } from '@/mixins/AuthMixin';

@Component({
  name: 'project-overview',
  components: { BotSettingsCard },
})
export default class ProjectOverview extends mixins(ProjectViewMixin, AuthMixin) {
  isDeleting = false;

  async requestDeleteConfirmation() {
    if (!this.bot) return;
    const confirmed = confirm(`Are you sure that you want to delete ${this.bot.name}?`);
    if (confirmed) {
      this.isDeleting = true;
      try {
        await this.$store.dispatch('bots/delete', this.bot.id);
        this.$notify.success('Successfully deleted project');
        await this.$router.replace({ name: AppRoute.Projects });
      } catch (e) {
        this.$notify.error({
          title: 'Deleting the project failed',
          description: GENERIC_ERROR_DESCRIPTION,
        });
      } finally {
        this.isDeleting = false;
      }
    }
  }
}
