
import BotFeaturesCheckboxGroup from '@/components/bot/BotFeaturesCheckboxGroup.vue';
import BotForm from '@/components/bot/BotForm.vue';
import { GENERIC_ERROR_DESCRIPTION } from '@/constants';
import { BotMixin } from '@/mixins/BotMixin';
import { BotSettings } from '@/types';
import isEqual from 'fast-deep-equal/es6';
import { DEFAULT_TIMEZONE, SupportedFeature } from 'ignite360-core';
import { mixins } from 'vue-class-component';
import { Component, Watch } from 'vue-property-decorator';
import InviteCollaboratorModal from '@/components/bot/InviteCollaboratorModal.vue';
import BotCollaborationSettings from '@/components/bot/BotCollaborationSettings.vue';

@Component({
  name: 'bot-settings-card',
  components: {
    BotCollaborationSettings,
    InviteCollaboratorModal,
    BotForm,
    BotFeaturesCheckboxGroup,
  },
})
export default class BotSettingsCard extends mixins(BotMixin) {
  botSettings: BotSettings = this.resetValue();

  isSubmitting = false;
  showInviteCollaboratorModal = false;

  get settingsHaveChanged(): boolean {
    return !isEqual(this.botSettings, this.resetValue());
  }

  async onSubmit() {
    this.isSubmitting = true;
    try {
      await this.$updateBot({
        id: this.bot.id,
        data: {
          ...this.botSettings,
          // only pass features that exist
          selectedFeatures: Array.from(this.botSettings.selectedFeatures).filter((feature) =>
            Object.values(SupportedFeature).some(
              (supportedFeature) => supportedFeature === feature,
            ),
          ),
        },
      });
      this.$notify.success('Successfully updated settings');
    } catch (e) {
      this.$notify.error({
        title: 'Updating settings failed',
        description: GENERIC_ERROR_DESCRIPTION,
      });
    } finally {
      this.isSubmitting = false;
    }
  }

  @Watch('bot', { immediate: true, deep: true })
  private reset() {
    this.botSettings = this.resetValue();
  }

  private resetValue(): BotSettings {
    return {
      name: this.bot.name,
      tenantId: this.bot.tenantId,
      domain: this.bot.domain,
      email: this.bot.email || '',
      selectedFeatures: new Set(this.bot.selectedFeatures),
      environment: this.bot.environment,
      timezone: this.bot.timezone || DEFAULT_TIMEZONE,
    };
  }
}
